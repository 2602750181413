<template>
  <div>
    <StudentForm @submitForm="submitForm"></StudentForm>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "StudentAdd",
  components: {
    StudentForm: () => import("./StudentDetail"),
  },
  methods: {
    submitForm(data) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.value) {
          ApiService.post("prep-app/student", data)
            .then(function (res) {
              if (res.status == 200) {
                vm.$toasted.success("Submit successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.$router.push({ name: "StudentList" });
              }
            })
            .catch(function (error) {
              if (error.response.status == 422) {
                let data_response_error =
                  error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            });
        }
      });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style></style>
